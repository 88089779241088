import "./SaveConfig.scss";
import { LargeLoader } from "../ui/loaders/Loader";
import Row from "../Row";
import { SetStateAction, useEffect, useState } from "react";

function SaveConfig({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: SetStateAction<any>;
}) {
  /*const [loading, setLoading] = useState(true);*/
  const [seconds, setSeconds] = useState(7);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  useEffect(() => {
    if (!loading && seconds > 0) {
      setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
  }, [loading, seconds]);

  return (
    <div className="save-config">
      <div className="save-config-header">
        <h2>{loading ? "Saving your configuration" : "Configuration saved"}</h2>
      </div>

      <Row className="save-config-content">
        {loading ? (
          <LargeLoader />
        ) : (
          <i className="bi bi-check-circle saved-icon"></i>
        )}
      </Row>
      <div className="save-config-content-item-text">
        {loading ? null : (
          <p>
            Your configuration has been saved. Redirecting to dashboard in{" "}
            {seconds} seconds. If you are not redirected, click button below.
          </p>
        )}
      </div>
    </div>
  );
}

export default SaveConfig;
