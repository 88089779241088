import Chevron from "../assets/svg/Chevron";
import React from "react";

function Skip({ handleSkip }: { handleSkip: any }) {
  return (
    <small onClick={handleSkip} style={{ cursor: "pointer" }}>
      Skip <Chevron />
    </small>
  );
}
export default Skip;
