/* eslint-disable react-hooks/exhaustive-deps */

import React, { SetStateAction, useEffect, useState } from "react";
import Skip from "../Skip";
function AboutYouConfig({
  aboutYou,
  setAboutYou,
  error,
  setError,
  handleSkip,
}: {
  aboutYou: string;
  setAboutYou: SetStateAction<any>;
  error: string;
  setError: SetStateAction<any>;
  handleSkip: any;
}) {
  const [charCount, setCharCount] = useState(0);

  const MAX_CHARACTERS = 100;
  useEffect(() => {
    // Check if the about text contains links
    const containsLink = (text: string) => {
      /*const urlRegex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*!/?",
        "gi"
      );*/
      const urlRegex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*!?",
        "gi"
      );
      return urlRegex.test(text);
    };

    if (containsLink(aboutYou)) {
      setError("Links are not allowed here. You can add them in the next step");
    } else {
      setError("");
    }
  }, [aboutYou]);
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const inputLength = inputValue.length;

    if (inputLength <= MAX_CHARACTERS) {
      setAboutYou(inputValue);
      setCharCount(inputLength);
    }
  };

  return (
    <div className={"input-group"}>
      <div className={"config-title"}>
        <label>About you</label>
        <Skip handleSkip={handleSkip} />
        <small className="the-count">
          {charCount}/{MAX_CHARACTERS}
        </small>
      </div>
      <div className={"input-container"}>
        <textarea
          id={"about-you-input"}
          value={aboutYou}
          onChange={handleInputChange}
          className={"about-you-textarea"}
          placeholder={"Tell others about your business"}
        />
      </div>

      <div className={"input-footer"}>
        {error ? <small className={"input-error"}>{error}</small> : null}
        <small>
          This will be visible to other users to improve the trust between you
          and other users. You can change this later
        </small>
      </div>
    </div>
  );
}
export default AboutYouConfig;
