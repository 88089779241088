function Row({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div
      className={className}
      style={{ display: "flex", flexDirection: "row", ...style }}
    >
      {children}
    </div>
  );
}
export default Row;
