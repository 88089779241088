import React from "react";
import "./ToolTip.scss";

interface ToolTipProps {
  text: string;
  direction: "top" | "bottom" | "left" | "right";
  children: React.ReactNode;
}

function ToolTip({ text, direction, children }: ToolTipProps) {
  return (
    <div className={`tooltip tooltip-${direction}`}>
      {children}
      <div className="tooltip-text">{text}</div>
    </div>
  );
}

export default ToolTip;
