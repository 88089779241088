import Row from "../../components/Row";

function RatingStar({ stars }: { stars: number }) {
  return (
    <Row style={{ gap: "4px" }}>
      {Array.from({ length: stars }, (_, i) => {
        return (
          <svg
            key={i}
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1358 6.40009L11.4339 8.60677C11.2715 8.73884 11.2 8.95345 11.2495 9.15706L12.0667 12.4946C12.1878 13.0366 11.7751 13.4851 11.3128 13.4851C11.1752 13.4851 11.0294 13.4438 10.8946 13.3558L7.93952 11.5398C7.76343 11.4325 7.54331 11.4325 7.36722 11.5426L4.44791 13.3585C4.31309 13.4493 4.16727 13.4879 4.02694 13.4879C3.55369 13.4879 3.12721 13.0394 3.27579 12.4973L4.09298 9.15706C4.1425 8.95345 4.07096 8.74159 3.91138 8.60952L1.24521 6.4056C0.697673 5.93785 1.01134 5.03812 1.71296 4.9996L5.16605 4.74922C5.37241 4.73546 5.55125 4.60339 5.62829 4.41354L6.94899 1.20534C7.08657 0.872409 7.38923 0.707321 7.69189 0.707321C7.99455 0.707321 8.29721 0.872409 8.43478 1.20534L9.75273 4.41079C9.82977 4.60064 10.0114 4.72996 10.215 4.74647L13.6681 4.99685C14.3724 5.03537 14.6834 5.93235 14.1358 6.40009Z"
              fill="#FFA800"
            />
          </svg>
        );
      })}
      {/*{Array.from({ length: 5 - stars }, (_, i) => {
        return (
          <svg
            key={-i}
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1358 6.40009L11.4339 8.60677C11.2715 8.73884 11.2 8.95345 11.2495 9.15706L12.0667 12.4946C12.1878 13.0366 11.7751 13.4851 11.3128 13.4851C11.1752 13.4851 11.0294 13.4438 10.8946 13.3558L7.93952 11.5398C7.76343 11.4325 7.54331 11.4325 7.36722 11.5426L4.44791 13.3585C4.31309 13.4493 4.16727 13.4879 4.02694 13.4879C3.55369 13.4879 3.12721 13.0394 3.27579 12.4973L4.09298 9.15706C4.1425 8.95345 4.07096 8.74159 3.91138 8.60952L1.24521 6.4056C0.697673 5.93785 1.01134 5.03812 1.71296 4.9996L5.16605 4.74922C5.37241 4.73546 5.55125 4.60339 5.62829 4.41354L6.94899 1.20534C7.08657 0.872409 7.38923 0.707321 7.69189 0.707321C7.99455 0.707321 8.29721 0.872409 8.43478 1.20534L9.75273 4.41079C9.82977 4.60064 10.0114 4.72996 10.215 4.74647L13.6681 4.99685C14.3724 5.03537 14.6834 5.93235 14.1358 6.40009Z"
              fill={"#BEBEBE"}
            />
          </svg>
        );
      })}*/}
    </Row>
  );
}
export default RatingStar;
