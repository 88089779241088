import React, { useEffect, useRef, useState } from "react";
import UsernameConfig from "./UsernameConfig";
import ProfilePictureConfig from "./ProfilePictureConfig";
import AboutYouConfig from "./AboutYouConfig";
import "./ConfigurationForm.scss";
import SocialsConfig from "./SocialsConfig";
import WalletsConfig from "./WalletsConfig";
import { SocialNetwork } from "../../types/SocialNetwork";
import ConfirmConfiguration from "./ConfirmConfiguration";
import SaveConfig from "./SaveConfig";
import Welcome from "./Welcome";
import Logo from "../../assets/svg/Logo";
import Column from "../Column";
import Button from "../Button";
import Delimiter from "../Delimiter";
import ConfigComponent from "./ConfigComponent";

function ConfigurationForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [animation, setAnimation] = useState("MoveLeftAndFadeIn");
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [profilePicture, setProfilePicture] = useState<
    string | ArrayBuffer | null
  >("");
  const [aboutYou, setAboutYou] = useState("");
  const [networkInputs, setNetworkInputs] = useState<SocialNetwork[]>([]);
  const [address, setAddress] = useState("");
  const [saving, setSaving] = useState(false);
  const [i, setI] = React.useState(0);
  // save configuration to local storage
  const [animationHeight, setAnimationHeight] = useState(0);
  useEffect(() => {
    const logo = document.getElementById("frame-logo");
    const welcomeLogo = document.getElementById("welcome-logo");
    let logoY = 0;
    let welcomeLogoY = 0;
    if (logo) {
      logo.style.opacity = "0";
      logoY = logo.getClientRects()[0].y;
    }
    console.log(welcomeLogo?.getClientRects()[0]);
    if (welcomeLogo) {
      welcomeLogoY = welcomeLogo.getClientRects()[0].y;
      console.log(welcomeLogoY);
    }
    let animationHeight = logoY - welcomeLogoY;
    if (welcomeLogoY !== 0) {
      setAnimationHeight(animationHeight);
    }
    if (logo && i > 1) {
      logo.style.opacity = "1";
    }
  }, [i]);

  useEffect(() => {
    const animation = document.createElement("style");
    animation.innerHTML = `
      @keyframes fade-in-move-top {
        0% {
          opacity: 0;
        }
        36% {
          opacity: 1;
        }
        80% {
          opacity: 1;
          transform: translateY(0);
        }
        100% {
          opacity: 1;
          transform: translateY(${animationHeight}px);
        }
      }
    `;
    document.head.appendChild(animation);
  }, [animationHeight]);

  const handleSave = () => {
    const configuration = {
      username,
      profilePicture,
      aboutYou,
      socials: networkInputs,
      walletAddress: address,
    };
    // save configuration to local storage
    localStorage.setItem("configuration", JSON.stringify(configuration));
  };
  //get configuration from local storage
  const handleGet = () => {
    const configuration = JSON.parse(localStorage.getItem("configuration")!);
    setUsername(configuration.username);
    setProfilePicture(configuration.profilePicture);
    setAboutYou(configuration.aboutYou);
    setNetworkInputs(configuration.socials);
    setAddress(configuration.walletAddress);
  };
  useEffect(() => {
    // get configuration from local storage and set it to state if it exists or save it to local storage if it doesn't
    try {
      handleGet();
    } catch {}
  }, []);

  const handleNext = () => {
    if (error || !username) {
      if (!username) {
        setError("Username cannot be empty");
      }
      setAnimation("ShakeIt");
      setTimeout(() => {
        setAnimation("none");
      }, 500);

      return;
    }

    if (currentStep === 4 && (error || !address)) {
      if (!address) {
        setError("Wallet address cannot be empty");
      }
      setAnimation("ShakeIt");
      setTimeout(() => {
        setAnimation("none");
      }, 500);

      return;
    }

    setAnimation("MoveLeftAndFadeOut");
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
      setAnimation("MoveLeftAndFadeIn");
    }, 500);
    handleSave();
  };

  const handleBack = () => {
    setAnimation("MoveRightAndFadeOut");
    setTimeout(() => {
      setCurrentStep(currentStep - 1);
      setAnimation("MoveRightAndFadeIn");
    }, 500);
  };
  const handleFinish = () => {
    /* setFinishing(true);*/
  };

  const configComponents = [
    <UsernameConfig
      value={username}
      setValue={setUsername}
      error={error}
      setError={setError}
    />,
    <ProfilePictureConfig
      profilePicture={profilePicture}
      setProfilePicture={setProfilePicture}
      handleSkip={handleNext}
    />,
    <AboutYouConfig
      aboutYou={aboutYou}
      setAboutYou={setAboutYou}
      error={error}
      setError={setError}
      handleSkip={handleNext}
    />,
    <SocialsConfig
      networkInputs={networkInputs}
      setNetworkInputs={setNetworkInputs}
      handleSkip={handleNext}
    />,
    <WalletsConfig
      address={address}
      setAddress={setAddress}
      error={error}
      setError={setError}
    />,
    <ConfirmConfiguration
      username={username}
      profilePicture={profilePicture}
      aboutYou={aboutYou}
      socials={networkInputs}
      walletAddress={address}
    />,
    <SaveConfig loading={saving} setLoading={setSaving} />,
  ];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Welcome
        children={[
          <h1 key={0} className={"welcome-to"}>
            Welcome to
          </h1>,
          <Logo key={1} className={"welcome-logo"} id={"welcome-logo"} />,
          <h2 key={2} className={"welcome-lets-go"}>
            Let's get you set up
          </h2>,
        ]}
        i={i}
        setI={setI}
      />

      {i > 2 ? (
        <Column>
          <div
            onAnimationEnd={() => {
              document.getElementById("username-input")?.focus();
              document.getElementById("about-you-input")?.focus();
              document.getElementById("wallets-input")?.focus();
            }}
          >
            <ConfigComponent
              component={configComponents[currentStep]}
              id={"step_" + currentStep.toString()}
              animation={animation}
              width={currentStep === configComponents.length - 2 ? "100%" : ""}
            />
          </div>
          {!saving ? (
            <Column className={"animated-left"}>
              <Delimiter width={"16%"} type={"solid"} />
              <div className="button-container">
                {currentStep > 0 &&
                  currentStep !== configComponents.length - 1 && (
                    <Button className="btn btn-secondary" onClick={handleBack}>
                      Back
                    </Button>
                  )}

                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    currentStep < configComponents.length - 1
                      ? handleNext()
                      : handleFinish();
                  }}
                >
                  {currentStep === configComponents.length - 2
                    ? "Save"
                    : currentStep === configComponents.length - 1
                    ? "Go to dashboard"
                    : "Next"}
                </Button>
              </div>
            </Column>
          ) : null}
        </Column>
      ) : null}
    </form>
  );
}

export default ConfigurationForm;
