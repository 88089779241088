import React, { useEffect } from "react";
import "./App.scss";
import "./assets/styles/light.scss";
import "./assets/styles/dark.scss";
import Logo from "./assets/svg/Logo";
import ConfigurationForm from "./components/initial-configuration/ConfigurationForm";

function App() {
  const [theme, setTheme] = React.useState(
    localStorage.getItem("theme") || "light"
  );
  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
        setTheme("dark");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  function toggleTheme() {
    setTheme(theme === "light" ? "dark" : "light");
  }

  return (
    <div className={`App theme ${theme}`}>
      {/*<div className={"theme-selector"}>
        <button onClick={toggleTheme}>Change theme</button>
      </div>*/}
      <div className={"container"}>
        <div className={"frame"}>
          <div className={"frame-header"}>
            <Logo id={"frame-logo"} />
          </div>

          <div className={"frame-content"}>
            <ConfigurationForm />
          </div>
          <div className={"frame-footer"}>
            <div onClick={toggleTheme} className={"theme-selector"}>
              <div
                className={`theme-item ${theme === "light" ? "active" : ""}`}
              >
                <i className={`bi bi-sun-fill`}></i>
              </div>
              <div className={`theme-item ${theme === "dark" ? "active" : ""}`}>
                <i className={`bi bi-moon-fill`}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
