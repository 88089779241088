/* eslint-disable react-hooks/exhaustive-deps */
import React, { SetStateAction, useEffect } from "react";

interface UsernameConfigProps {
  value: string;
  setValue: SetStateAction<any>;
  error: string;
  setError: SetStateAction<any>;
}

function UsernameConfig({
  value,
  setValue,
  error,
  setError,
}: UsernameConfigProps) {
  const handleInputChange = (event: any) => {
    // Allow only letters, numbers, and underscores
    const newValue = event.target.value.replace(/[^A-Za-z0-9_]/g, "");
    setValue(newValue);
  };

  useEffect(() => {
    if (value && value.length < 4) {
      setError("Username must be at least 4 characters long");
    } else {
      setError("");
    }
  }, [value]);

  return (
    <div className={"input-group"} id={"username-form"}>
      <label>Create username</label>
      <div className="input-container">
        <div className="input-icon">
          <i className="bi-person"></i>
        </div>
        <input
          id={"username-input"}
          type="text"
          className="input-field"
          placeholder="e.g. kindness_center"
          onChange={handleInputChange}
          value={value}
        />
      </div>
      <div className={"input-footer"}>
        {error ? <small className={"input-error"}>{error}</small> : null}
        <small>
          This will be your username and will be visible to other users. You can
          change this later
        </small>
      </div>
    </div>
  );
}

export default UsernameConfig;
