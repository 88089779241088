import React from "react";
import "./ConfigComponent.scss";

interface ConfigComponentProps {
  component: React.ReactNode;
  id: string;
  animation: string;
  width?: string;
}

function ConfigComponent({
  component,
  id,
  animation,
  width,
}: ConfigComponentProps) {
  return (
    <div
      id={id}
      className={`config-component`}
      style={{ animation: `${animation} 0.55s ease`, width: `${width}` }}
    >
      {component}
    </div>
  );
}

export default ConfigComponent;
