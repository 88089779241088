import React, { SetStateAction } from "react";
import "./WordAnimation.scss";
import Column from "../Column";

const Welcome = ({
  children,
  i,
  setI,
}: {
  children: any[];
  i: number;
  setI: SetStateAction<any>;
}) => {
  return (
    <Column className="word-animation">
      <div
        onAnimationEnd={() => {
          console.log("animation ended");
          setI(i + 1);
        }}
      >
        {children[i]}
      </div>
    </Column>
  );
};
export default Welcome;
