import { Icon } from "../../../assets/svg/SocialIcons";

function CoinSelector({ coin }: { coin: string }) {
  return (
    <div className="coin-selector">
      <div className="coin-selector-icon">
        <Icon name={coin} />
      </div>
      <div className="coin-selector-name">
        <label>{coin}</label>
      </div>
    </div>
  );
}
export default CoinSelector;
