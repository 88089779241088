interface ButtonProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  className: string;
}

function Button({ children, onClick, className }: ButtonProps) {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
