function Chevron({ className }: { className?: string }) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow / Chevron_Right_Duo">
        <path
          id="Vector"
          d="M13 8L17 12L13 16M7 8L11 12L7 16"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
export default Chevron;
