import React, { SetStateAction } from "react";
import UploadFile from "../../assets/svg/UploadFile";
import Button from "../Button";
import Column from "../Column";
import Skip from "../Skip";

function ProfilePictureConfig({
  profilePicture,
  setProfilePicture,
  handleSkip,
}: {
  profilePicture: string | ArrayBuffer | null;
  setProfilePicture: SetStateAction<any>;
  handleSkip: any;
}) {
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handlePictureUpload = (file: File | null) => {
    if (file) {
      // Check if the file size is larger than 1MB (1048576 bytes)
      if (file) {
        // If the file size is too big, compress the image using the canvas API
        const image = new Image();
        const reader = new FileReader();
        reader.onload = () => {
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const maxSize = 500;
            let width = image.width;
            let height = image.height;
            console.log(width, height);
            // Determine the ratio of the original image to the maximum size
            let ratio = 1;
            if (width > maxSize || height > maxSize) {
              ratio = Math.max(maxSize / width, maxSize / height);
              width *= ratio;
              height *= ratio;
            }

            canvas.width = maxSize;
            canvas.height = maxSize;
            const context = canvas.getContext("2d");

            // Calculate the x and y coordinates to center the image
            const x = maxSize / 2 - width / 2;
            const y = maxSize / 2 - height / 2;

            context?.drawImage(
              image,
              0,
              0,
              image.width,
              image.height,
              x,
              y,
              width,
              height
            );
            const compressedImage = canvas.toDataURL("image/jpeg", 0.7);
            setProfilePicture(compressedImage);
          };
          image.src = reader.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        // If the file size is within the limit, set the profilePicture state to the uploaded image
        const reader = new FileReader();
        reader.onload = () => {
          setProfilePicture(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    handlePictureUpload(file);
  };

  return (
    <div onDragOver={handleDragOver}>
      <div className={"config-title"}>
        <label htmlFor="profilePicture">Add profile picture</label>
        {!profilePicture && <Skip handleSkip={handleSkip} />}
      </div>
      {!profilePicture && (
        <div onDrop={handleDrop}>
          <label htmlFor="file-upload" className="custom-file-upload">
            <UploadFile className={"uploader-icon"} />
            Drop picture here or click to upload
          </label>

          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={(event) => {
              event.preventDefault();
              const file = event.target.files && event.target.files[0];
              handlePictureUpload(file);
            }}
          />
        </div>
      )}
      {profilePicture && (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <Column>
          <img
            className={"profile-picture-preview"}
            src={profilePicture.toString()}
            alt={""}
            style={{}}
          />
          <Button
            onClick={() => {
              setProfilePicture("");
            }}
            className={"btn btn-transparent"}
          >
            Remove
          </Button>
        </Column>
      )}
    </div>
  );
}

export default ProfilePictureConfig;
