function NoAvatar() {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.756 104.528C27.835 98.5255 30.2742 92.7956 34.5458 88.5778C38.8173 84.3601 44.5777 81.9938 50.5807 81.9908H78.0092C84.0121 81.9939 89.7725 84.3603 94.044 88.578C98.3155 92.7957 100.755 98.5256 100.834 104.528C90.8081 113.562 77.7908 118.562 64.295 118.562C50.7991 118.562 37.7818 113.562 27.756 104.528ZM84.8664 47.7051C84.8664 51.7737 83.6599 55.751 81.3995 59.134C79.1391 62.5169 75.9262 65.1536 72.1673 66.7106C68.4084 68.2676 64.2721 68.675 60.2817 67.8812C56.2912 67.0875 52.6257 65.1283 49.7488 62.2513C46.8718 59.3743 44.9126 55.7088 44.1188 51.7184C43.325 47.7279 43.7324 43.5917 45.2894 39.8327C46.8464 36.0738 49.4831 32.861 52.8661 30.6006C56.249 28.3402 60.2263 27.1337 64.295 27.1337C69.7508 27.1337 74.9833 29.301 78.8411 33.1589C82.699 37.0168 84.8664 42.2492 84.8664 47.7051Z"
        fill="#D9D9D9"
      />
      <path
        d="M113.433 104.528C119.996 96.6291 124.561 87.2657 126.74 77.2298C128.92 67.194 128.65 56.7808 125.955 46.8711C123.259 36.9613 118.216 27.8466 111.253 20.2977C104.29 12.7489 95.6116 6.98802 85.9514 3.50238C76.2911 0.0167416 65.9336 -1.09112 55.7547 0.272491C45.5758 1.6361 35.875 5.43109 27.4728 11.3364C19.0707 17.2418 12.2143 25.0838 7.48369 34.1992C2.75305 43.3146 0.287277 53.4352 0.29494 63.705C0.29852 78.6362 5.56035 93.0892 15.1571 104.528L15.0657 104.604C15.3857 104.99 15.7514 105.32 16.0815 105.697C16.4929 106.171 16.9336 106.612 17.3615 107.072C18.6415 108.459 19.9553 109.793 21.3386 111.05C21.7569 111.429 22.1907 111.786 22.6186 112.154C24.0815 113.416 25.5827 114.61 27.1443 115.726C27.3459 115.869 27.5247 116.043 27.7276 116.186V116.128C38.4344 123.662 51.207 127.706 64.2991 127.706C77.3911 127.706 90.1637 123.662 100.87 116.128V116.185C101.073 116.042 101.252 115.868 101.454 115.725C103.014 114.609 104.517 113.415 105.98 112.154C106.407 111.788 106.841 111.428 107.26 111.049C108.642 109.792 109.957 108.458 111.237 107.071C111.663 106.612 112.102 106.17 112.517 105.696C112.845 105.319 113.212 104.989 113.532 104.603L113.433 104.528ZM64.2949 27.1336C68.3636 27.1336 72.3409 28.3401 75.7238 30.6005C79.1068 32.861 81.7435 36.0738 83.3005 39.8327C84.8575 43.5916 85.2648 47.7279 84.4711 51.7183C83.6773 55.7088 81.7181 59.3743 78.8411 62.2512C75.9642 65.1282 72.2987 67.0875 68.3082 67.8812C64.3178 68.675 60.1815 68.2676 56.4226 66.7106C52.6637 65.1536 49.4508 62.5169 47.1904 59.1339C44.93 55.751 43.7235 51.7737 43.7235 47.705C43.7235 42.2492 45.8909 37.0167 49.7487 33.1589C53.6066 29.301 58.8391 27.1336 64.2949 27.1336ZM27.756 104.528C27.8349 98.5254 30.2741 92.7954 34.5456 88.5777C38.8172 84.3599 44.5777 81.9936 50.5807 81.9908H78.0092C84.0122 81.9937 89.7726 84.3601 94.0441 88.5778C98.3157 92.7955 100.755 98.5255 100.834 104.528C90.8081 113.562 77.7908 118.562 64.2949 118.562C50.7991 118.562 37.7818 113.562 27.756 104.528Z"
        fill="#949296"
      />
    </svg>
  );
}
export default NoAvatar;
