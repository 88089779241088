function UsdtTrc() {
  return (
    <svg
      width="60"
      height="50"
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 25C50 38.8078 38.8074 50 24.9999 50C11.193 50 0 38.8078 0 25C0 11.1928 11.193 0 24.9999 0C38.8074 0 50 11.1928 50 25Z"
        fill="#1BA27A"
      />
      <path
        d="M37 13H13V18.6684H22.103V27H27.897V18.6684H37V13Z"
        fill="white"
      />
      <path
        d="M25.0001 27.643C17.6225 27.643 11.6411 26.4529 11.6411 24.9846C11.6411 23.5166 17.6222 22.3263 25.0001 22.3263C32.3778 22.3263 38.3589 23.5166 38.3589 24.9846C38.3589 26.4529 32.3778 27.643 25.0001 27.643ZM40 25.4278C40 23.5346 33.2844 22 25.0001 22C16.7161 22 10 23.5346 10 25.4278C10 27.0951 15.2076 28.4841 22.1082 28.7919V41H27.7843V28.7967C34.7381 28.4985 40 27.1037 40 25.4278Z"
        fill="white"
      />
      <path
        d="M46 40C53.732 40 60 33.732 60 26C60 18.268 53.732 12 46 12C38.268 12 32 18.268 32 26C32 33.732 38.268 40 46 40Z"
        fill="#FF060A"
      />
      <path
        d="M55.7414 23.7239C54.8044 22.9016 53.5081 21.6458 52.4522 20.7552L52.3898 20.7136C52.286 20.6343 52.1686 20.5721 52.0432 20.5296C49.4973 20.0783 37.6497 17.9735 37.4186 18.0003C37.3538 18.0089 37.2919 18.0312 37.2374 18.0656L37.1781 18.1101C37.105 18.1806 37.0495 18.2658 37.0156 18.3595L37 18.3981V18.6088V18.6415C38.3338 22.1713 43.6002 33.7344 44.6372 36.4477C44.6997 36.632 44.8183 36.9823 45.0401 37H45.0901C45.2088 37 45.7148 36.3647 45.7148 36.3647C45.7148 36.3647 54.7607 25.9386 55.676 24.8283C55.7945 24.6915 55.8992 24.5444 55.9881 24.3889C56.0112 24.2673 56.0004 24.1421 55.9572 24.0255C55.9139 23.909 55.8397 23.8051 55.7414 23.7239ZM48.0358 24.9381L51.8962 21.8952L54.1609 23.8783L48.0358 24.9381ZM46.5365 24.7392L39.8894 19.5618L50.6437 21.4469L46.5365 24.7392ZM47.1362 26.0959L53.9392 25.0539L46.1614 33.9602L47.1362 26.0959ZM38.9866 20.0783L45.9805 25.7189L44.9683 33.9658L38.9866 20.0783Z"
        fill="white"
      />
    </svg>
  );
}
export default UsdtTrc;
