import Skip from "../Skip";
import React, { SetStateAction } from "react";
import { SocialNetwork } from "../../types/SocialNetwork";
import Row from "../Row";
import "./SocialsConfig.scss";
import Column from "../Column";
import { Icon } from "../../assets/svg/SocialIcons";

const socialNetworks: SocialNetwork[] = [
  { name: "Telegram" },
  { name: "Facebook" },
  { name: "Instagram" },
  { name: "YouTube" },
  { name: "Twitter" },
  { name: "Website" },
];

function SocialsConfig({
  networkInputs,
  setNetworkInputs,
  handleSkip,
}: {
  networkInputs: SocialNetwork[];
  setNetworkInputs: SetStateAction<any>;
  handleSkip: any;
}) {
  const handleAddNetworkInput = (network: SocialNetwork) => {
    if (!networkInputs.some((input) => input.name === network.name)) {
      setNetworkInputs([...networkInputs, network]);
    }
  };

  const handleRemoveNetworkInput = (network: SocialNetwork) => {
    // @ts-ignore
    document.getElementById(`social-input-${network.name}`).style.animation =
      "MoveLeftAndFadeOut 0.5s ease";
    setTimeout(() => {
      setNetworkInputs(
        networkInputs.filter((input) => input.name !== network.name)
      );
    }, 500);
  };

  const handleInputChange = (network: SocialNetwork, value: string) => {
    setNetworkInputs(
      networkInputs.map((input) =>
        input.name === network.name ? { ...input, value } : input
      )
    );
  };

  return (
    <div>
      <div className={"config-title"}>
        <label>Add social media links</label> <Skip handleSkip={handleSkip} />
      </div>
      <Column>
        {networkInputs.map((network) => (
          <div
            key={network.name}
            className={"input-container social-input"}
            id={`social-input-${network.name}`}
          >
            <div className={"input-icon"}>
              <Icon name={network.name} />
            </div>

            <input
              style={{ fontSize: "16px" }}
              className={"input-field"}
              id={`social-input-${network.name}`}
              type="text"
              placeholder={`Enter your ${network.name}`}
              value={
                networkInputs.find((input) => input.name === network.name)
                  ?.value
              }
              onChange={(event) =>
                handleInputChange(network, event.target.value)
              }
            />

            <div className={"social-input-remove"}>
              <i
                className={`bi bi-trash`}
                onClick={() => handleRemoveNetworkInput(network)}
              />
            </div>
          </div>
        ))}
      </Column>
      <Row className={"socials-container"}>
        {socialNetworks.map((network) => (
          <div key={network.name}>
            {networkInputs.some(
              (input) => input.name === network.name
            ) ? null : (
              <div
                key={network.name}
                className={"social-tile"}
                onClick={() => {
                  handleAddNetworkInput(network);
                }}
              >
                <div className={"tile-icon"}>
                  <Icon name={network.name} />
                </div>
                <div className={"tile-text"}>
                  <label>{network.name}</label>
                </div>
              </div>
            )}
          </div>
        ))}
      </Row>
    </div>
  );
}

export default SocialsConfig;
