/* eslint-disable react-hooks/exhaustive-deps */

import ToolTip from "../ui/tooltip/ToolTip";
import UsdtTrc from "../../assets/svg/UsdtTrc";
import { useEffect } from "react";

function WalletsConfig({
  address,
  setAddress,
  error,
  setError,
}: {
  address: string;
  setAddress: any;
  error: string;
  setError: any;
}) {
  /*const [address, setAddress] = useState("");
  const [error, setError] = useState("");*/
  function isValidUSDTTRC20Address(address: string): boolean {
    const USDTTRC20Regex = /^T[1-9a-km-zA-HJ-NP-Z]{33}$/;

    return USDTTRC20Regex.test(address);
  }

  function handleAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setAddress(value);
  }

  useEffect(() => {
    if (address && !isValidUSDTTRC20Address(address)) {
      setError("Please enter a valid USDT TRC20 wallet address");
    } else {
      setError("");
    }
  }, [address]);
  return (
    <div>
      <div className={"config-title"}>
        <label>WalletsConfig</label>
        <div style={{ position: "relative" }}>
          <ToolTip
            text={
              //detailed explanation of USDT TRC20
              "USDT TRC20 is a stablecoin that is pegged to the US dollar. It is a token that is issued on the TRON blockchain by  Tether Limited. " +
              "That means that it is backed by the US dollar and its value is stable. " +
              "This is most popular and convenient stablecoin for payments processing."
            }
            direction={"top"}
          >
            <small style={{ width: "100%" }}>What is USDT TRC20?</small>
            <i
              className={`bi bi-question-circle`}
              style={{ marginLeft: "4px" }}
            />
          </ToolTip>
        </div>
      </div>
      <div className={"input-container"}>
        <div className={"input-icon"} id={"wallet-icon"}>
          <UsdtTrc />
        </div>
        <input
          className={"input-field"}
          id={"wallets-input"}
          type="text"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter your USDT TRC20 wallet address"
        />
      </div>
      <div className={"input-footer"}>
        {error ? <small className={"input-error"}>{error}</small> : null}
        <small>
          Nobody will be able to see your wallet address. This will be your
          wallet to receive funds. <br /> <br /> Your customers will be able to
          pay in any currency and the funds will be converted to USDT TRC20 due
          to the low transaction fees, fast transaction times, high liquidity
          and stable price. You can change this later
        </small>
      </div>
    </div>
  );
}

export default WalletsConfig;
