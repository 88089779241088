import "./InfoBox.scss";
function InfoBox({ text }: { text: string }) {
  return (
    <div className="info-box">
      <h2>ATTENTION</h2>
      <p>{text}</p>
    </div>
  );
}
export default InfoBox;
