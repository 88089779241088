import { SocialNetwork } from "../../types/SocialNetwork";
import "./ConfirmConfiguration.scss";
import Delimiter from "../Delimiter";
import Row from "../Row";
import "../pages/payment/PaymentPage.scss";
import Column from "../Column";
import VerifiedMark from "../../assets/svg/VerifiedMark";
import RatingStar from "../../assets/svg/RatingStar";
import NoAvatar from "../../assets/svg/NoAvatar";
import { Icon } from "../../assets/svg/SocialIcons";
import React from "react";
import InfoBox from "../ui/info-box/InfoBox";
import LockedInput from "../ui/wallet-coin-line/LockedInput";
import CoinSelector from "../ui/wallet-coin-line/CoinSelector";

function ConfirmConfiguration({
  username,
  profilePicture,
  aboutYou,
  socials,
  walletAddress,
}: {
  username: string;
  profilePicture: string | ArrayBuffer | null;
  aboutYou: string;
  socials: SocialNetwork[];
  walletAddress: string;
}) {
  return (
    <div className={"confirm-configuration"}>
      <div className={"confirm-configuration-header"}>
        <h2 style={{ margin: "unset" }}>Public</h2>
      </div>
      <Row className={"payment-pay-to"}>
        <div className={"avatar-large"}>
          {profilePicture ? (
            <img
              src={profilePicture.toString()}
              alt="Profile"
              className={"avatar-large"}
            />
          ) : (
            <NoAvatar />
          )}
        </div>
        <Column>
          <div className={"username-container"}>
            <p className={"username"}>{username}</p> <VerifiedMark />
          </div>
          <RatingStar stars={4} />
          <p className={"about"}>{aboutYou}</p>
        </Column>
        <div className={"payment-socials"}>
          <Row>
            {socials.map((social) => {
              return (
                <div key={social.name}>
                  {social.name === "Website" ? null : (
                    <div className={"payment-socials-item"}>
                      <a href={social.value} target="_blank" rel="noreferrer">
                        <Icon name={social.name} />
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </Row>
          {
            //website from socials
            socials.find((social) => social.name === "Website") ? (
              <div className={"payment-socials-item"}>
                <a
                  href={
                    socials.find((social) => social.name === "Website")?.value
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {socials.find((social) => social.name === "Website")?.value}
                </a>
              </div>
            ) : null
          }
        </div>
      </Row>
      <Delimiter width={"100%"} style={{ margin: "unset" }} />
      <h2 style={{ marginTop: "20px" }}>Private</h2>
      <div
        className={"confirm-configuration-body-item"}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label>Wallet address</label>
        <div className={"payment-line"}>
          <LockedInput text={walletAddress} />
          <CoinSelector coin={"usdt trc20"} />
          {/*<div
            className={"input-container disabled"}
            style={{
              alignSelf: "center",
            }}
          >
            <div className={"input-icon"}>
              <UsdtTrc />
            </div>
            <input
              style={{
                width: "420px",
                fontSize: "16px",
                color: "inherit",
              }}
              className={"input-field"}
              value={walletAddress}
              disabled
            />
          </div>*/}
        </div>
      </div>
      <Delimiter width={"100%"} style={{ margin: "unset" }} />
      <InfoBox
        text={
          "Please review your configuration and make sure everything is correct. Consider that rating stars and verified mark are just for demo purposes and will not be shown by default. You can edit everything later."
        }
      />
      {/* <div className={"input-footer"}>
         <small>
          Please review your configuration and make sure everything is correct.
          You can change this later
        </small>
      </div>*/}
    </div>
  );
}

export default ConfirmConfiguration;
