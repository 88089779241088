function Delimiter({
  width,
  type,
  style,
}: {
  width: string;
  type?: any;
  style?: any;
}) {
  return (
    <div
      className="delimiter"
      style={{ width: `${width}`, borderTopStyle: type, ...style }}
    ></div>
  );
}
export default Delimiter;
